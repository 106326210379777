import React, {useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import img from '../../images/img.png'
import './styles.scss'
import i1 from '../../images/i1.svg'
import i2 from '../../images/i2.svg'
import i3 from '../../images/i3.svg'

export default function Features() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='features-wrapper' id='features'>
      <div className='features-container'>
        <img src={img} alt=""/>
        <h1>Bundled with Innovative Features</h1>
        <div> 
          <div className='featuresCard-wrapper1' data-aos="fade-zoom-in">
            <img src={i1} alt="" />
            <h5>Fully Dedicated</h5>
            <p>Our proxies are 100% private, and is not shared</p>
          </div>

          <div className='featuresCard-wrapper2' data-aos="fade-zoom-in">
            <img src={i2} alt="" />
            <h5>100% Guaranteed Uptime</h5>
            <p>Our 10 GBPS servers stays up and running for 24 hours 7 days a
week. For providing the fastest proxy solutions to our clients</p>
          </div>

          <div className='featuresCard-wrapper3' data-aos="fade-zoom-in">
            <img src={i3} alt="" />
            <h5>24/7 Support</h5>
            <p>Our team works 24/7 for providing the most reliable and friendly
customer service</p>
          </div>
        </div>
       
      </div>
    </div>
  )
}
