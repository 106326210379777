import React, {useEffect, useState} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './styles.scss'

export default function PlanCard(props) {
    const {data} = props;
    const [selectValue, setSelectValue] = useState(data.p1)
    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
 
  return (
    <div className='planCard-wrapper' data-aos="zoom-right">
        <div className='top'>
            <h3>{data.title}</h3>
            <h1>${selectValue}</h1>
        </div>
        <div className='bottom'>
            <ul>
                <li>{data.l1}</li>
                <li>{data.l2}</li>
                <li>{data.l3}</li>
                {data.l4 &&<li>{data.l4}</li>}
                {data.l5 && <li>{data.l5}</li>}
                {data.l6 && <li>{data.l6}</li>}
                {data.l7 && <li>{data.l7}</li>}
                {data.l8 && <li>{data.l8}</li>}
                {data.l9 && <li>{data.l9}</li>}
            </ul>
            <p>{data.p}</p>
            <select value={selectValue} onChange={(e)=>setSelectValue(e.target.value)}>
                <option value={data.p1}>{data.opt1} | {data.p1}</option>
                <option value={data.p2}>{data.opt2} | {data.p2}</option>
                {data.p3 && <option value={data.p3}>{data.opt3} | {data.p3}</option>}
                {data.p4 && <option value={data.p4}>{data.opt4} | {data.p4}</option>}
            
            </select>
            <a href="https://dashboard.questionproxy.com/"><button>Buy Now</button></a>
        </div>
    </div>
  )
}
