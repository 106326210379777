import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'
import twitter from '../../images/twitter.png'
import discord from '../../images/discord.webp'
import tiktok from '../../images/tiktok.png'

export default function Footer() {
  return (
    <div className='footer-wrapper'>
      <div className='footer-content'>
        <div>
        <div>
        <h1>Company</h1>
        <Link to="/pricing">Pricing</Link>
        
       </div>

      <div>
        <h1>Information</h1>
        <Link>Terms and Conditions</Link>
        <Link>Privacy Policy</Link>
       </div>

       <div>
        <h1>Socials</h1>
        <div>
          <a href="https://twitter.com/questionproxies?lang=en"><img src={twitter} alt="" /></a>
          <a href="https://discord.gg/XW9VEmMAJe"><img src={discord} alt="" /></a>
          <a href="https://www.tiktok.com/@questionproxies"><img src={tiktok} alt="" /></a>
        </div>
       </div>
        </div>
      
       <p>
2022 QUESTION PROXIES</p>
      </div>
    </div>
  )
}
