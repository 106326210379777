import React, { useState } from 'react'
import PlanCard from './PlanCard/PlanCard'
import './styles.scss'

export default function Plans() {
  const [clicked, setClicked] = useState('isp')
  return (
    <div className='plans-wrapper' id="plans">
      <div className='plans-container'>
        <h1><span>PRICING</span> PLANS</h1>
        <h4>USE CODE QUESTION FOR A DISCOUNT</h4>

        <div className='tabs-container'>
          <button className={clicked === 'isp' ? 'selected' : ""} onClick={()=>setClicked('isp')}>PREMIUM ISP PLANS</button>
          {/* <button className={clicked === 'captcha' ? 'selected' : ""} onClick={()=>setClicked('captcha')}>PREMIUM ACCOUNTS</button> */}
        </div>

        {clicked === 'isp'  && <div className='content'>
          {[
            {
              title: "TIER1 2.0",
              p1: "52.5",
              p2: "105",
              p3: "210",
              p4: "315",

              l1: "Sneakers: Footlocker*, Nike, YeezySupply, Finishline/JD, Adidas, Shopify, DSG & more",
              l2: "Retail: Amazon, BestBuy, Walmart, Target & more",
              l3: "Dedicated Private ISP's.",
              l4: "Comcast - Verizon.",
              l5: "Hosted in Ashburn, Virginia.",
              l6: "Locked on Footsites and YS between major drops.",
              l7: "10 GB/s in a private circuit Network.",
              l8: "Unlimited Bandwidth.",
              l9: "User:Pass Authentication.",

              p: "*Proxies are not guaranteed to work on any sites with strict Proxy/Bot Protection or those not listed above. We reserve the right to block access on specific sites to avoid IP bans. ALL SALES FINAL. No refunds/returns/cancellations will be provided.",
             
              opt1: "25 ISPs",
              opt2: "50 ISPs",
              opt3: "100 ISPs",
              opt4: "150 ISPs",
        
            },
            {
              title: "TIER1",
              p1: "46.25",
              p2: "92.5",
              p3: "185",
              p4: "277.5",

              l1: "Sneakers: Footlocker*, Nike, YeezySupply, Finishline/JD, Adidas, Shopify, DSG & more",
              l2: "Retail: Amazon, BestBuy, Walmart, Target & more",
              l3: "Dedicated Private ISP's.",
              l4: "AT&T",
              l5: "Hosted in Ashburn, Virginia.",
              l6: "Locked on Footsites and YS between major drops.",
              l7: "10 GB/s in a private circuit Network.",
              l8: "Unlimited Bandwidth.",
              l9: "User:Pass Authentication.",

              p:"*Proxies are not guaranteed to work on any sites with strict Proxy/Bot Protection or those not listed above. We reserve the right to block access on specific sites to avoid IP bans. ALL SALES FINAL. No refunds/returns/cancellations will be provided.",

            
              opt1: "25 ISPs",
              opt2: "50 ISPs",
              opt3: "100 ISPs",
              opt4: "150 ISPs",
            },
            {
              title: "NIKE/SNKRS ISP",
              p1: "41.25",
              p2: "82.5",
              p3: "165",
              p4: "247.5",

              l1: "Sneakers: Shopify, NIKE/SNKRS",
              l2: "Retail: Amazon, BestBuy, Walmart, Target & more",
              l3: "Dedicated Private ISP's.",
              l4: "AT&T - TIER2 MIX",
              l5: "Hosted in Ashburn, Virginia.",
              l6: "10 GB/s in a private circuit Network.",
              l7: "Unlimited Bandwidth.",
              l8: "User:Pass Authentication.",
              p: "*Proxies are not guaranteed to work on any sites with strict Proxy/Bot Protection or those not listed above. We reserve the right to block access on specific sites to avoid IP bans. ALL SALES FINAL. No refunds/returns/cancellations will be provided.",
              
              opt1: "25 ISPs",
              opt2: "50 ISPs",
              opt3: "100 ISPs",
              opt4: "150 ISPs",

            },
            {
              title: "UK/EU NIKE ISP",
              p1: "42.50",
              p2: "85",
              p3: "170",


              l1: "All sites including Nike & Retail",
              l2: "Virgin Media",
              l3: "Dedicated Private ISP's.",
              l4: "20 - 40 MS on server",
              l5: "10 GB/s in a private circuit Network.",
              l6: "User:Pass Authentication.",
              l7: "Unlimited Bandwidth.",
            
              p: "*Proxies are not guaranteed to work on any sites with strict Proxy/Bot Protection or those not listed above. We reserve the right to block access on specific sites to avoid IP bans. ALL SALES FINAL. No refunds/returns/cancellations will be provided.",
              
              opt1: "25 ISPs",
              opt2: "50 ISPs",
              opt3: "100 ISPs",

            }
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}

        {/* {clicked === 'captcha'  && <div className='content'>
          {[
            {
              title: "Nike/SNKRS Account",
              p1: "22.5",
              p2: "45",

              l1: "Hand made accounts",
              l2: "Comes with proxy for 30 days (same proxy that created the account)",
              l3: "Phone Verified",

              p: "*Proxies are not guaranteed to work on any sites with strict Proxy/Bot Protection or those not listed above. We reserve the right to block access on specific sites to avoid IP bans. ALL SALES FINAL. No refunds/returns/cancellations will be provided.",
             
              opt1: "5",
              opt2: "10",
             
        
            },
          
           
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>} */}
      </div>
    </div>
  )
}
