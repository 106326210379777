import React from 'react'
import { Link } from 'react-router-dom'
import p1 from '../../images/p1.webp'
import p2 from '../../images/p2.svg'
import p3 from '../../images/p3.svg'
import './styles.scss'

export default function SelectPlan() {
  return (
    <div className='selectplan-wrapper'>
      <div className='selectplan-container'>
        <h1>Select a Plan</h1>
        <div>
            <Link to="/pricing"><img src={p2} alt="" /></Link>
            {/* <Link to="/pricing"><img src={p3} alt="" /></Link> */}
        </div>
      </div>
    </div>
  )
}
