import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import MobileNavbar from './components/MobileNavbar/MobileNavbar'
import Main from "./pages/Main/Main";
import Plans from "./pages/Plans/Plans";

function App() {
  return (
    <div className="App">
      <Navbar />
      <MobileNavbar />
      <Routes>
        <Route path="/pricing" element={<Plans />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
