import React from 'react';
import Home from '../Home/Home'
import Features from '../Features/Features'
import Footer from '../../components/Footer/Footer'
import SelectPlan from '../SelectPlan/SelectPlan';
import Platforms from '../Platforms/Platforms';

export default function Main() {
  return <div>
      <Home />
      <Features />
      <SelectPlan />
      <Platforms />
      <Footer /> 
  </div>;
}
