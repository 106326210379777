import React, {useEffect, useState} from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import burger from '../../images/burger.svg'
import './MobileNavbar.scss';

function MobileNavbar() {
    const [isVisible, setIsVisible] = useState();
    const [nav, setNav] = useState(false);

    const toggle = () => setIsVisible(!isVisible);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
          setNav(true);
        } else {
          setNav(false);
        }
      };
      
    const largeScreens = () => {
        if (window.innerWidth > 1161) {
            setIsVisible(false);
        } 
      };
      useEffect(() => {
        window.addEventListener('resize', largeScreens);
      }, []);

      useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
          window.removeEventListener('scroll', changeBackground);
        };
      }, []);

    return (
        <div data-aos="fade-up" className={nav ? 'mobileNavbar-wrapper active':'mobileNavbar-wrapper'}>
            <div className='mobileNavbar-container'>
                <div className='top'>
                <Link to="/"><div><img src={logo} alt="" /></div></Link>    
                    <img src={burger} alt="" onClick={toggle} /> 
                </div>
                
                {isVisible && (
                <div  data-aos="fade-down" className='bottom' onClick={toggle}>
                    <HashLink smooth to="/#home">HOME</HashLink>
                    <Link to="/pricing">PRICING</Link>
                    <HashLink smooth to="/#terms">TERMS</HashLink>
                    <Link to="/contact">CONTACT US</Link>
                    <div><a href="https://dashboard.questionproxy.com/" target="_blank">DASHBOARD</a></div>
                </div>
                )}
            </div>
        </div>
    )
}

export default MobileNavbar
