import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { HashLink } from 'react-router-hash-link';
import './styles.scss'
import { Link } from 'react-router-dom';

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return  <div className='home-wrapper' id="home" data-aos="zoom-in">
      <div className='home-container'>
          <h1>QUESTION PROXIES</h1>
          <h2>Speed and Dominance</h2>
          <h2>in One Platform.</h2>
          <div>
            <HashLink smooth to="/#features"><button className='b1'> Learn More</button></HashLink>
            <Link to="/pricing"><button className='b2'> View Plans</button></Link>
          </div>
         
      </div> 
  </div>;
}
