import React from 'react'
import addidas from '../../images/addidas.webp'
import snkrs from '../../images/snkrs.jpeg'
import supreme from '../../images/supreme.webp'
import shopify from '../../images/shopify.webp'
import finish from '../../images/finish.webp'
import nike from '../../images/nike.webp'
import join from '../../images/join.png'
import './styles.scss'
import { Link } from 'react-router-dom'

export default function Platforms() {
  return (
    <div className='platforms-wrapper'>
      <div className='overlay'></div>
      <div className='platforms-container'>
        
        <h1>Dominating Multiple Platforms</h1>
        <div className='content'>
            <img src={addidas} alt="" />
            <img src={snkrs} alt="" />
            <img src={supreme} alt="" />
            <img src={shopify} alt="" />
            <img src={finish} alt="" />
            <img src={nike} alt="" />
        </div>
       <div className='join'>
          <div>
            <h2>QUESTION PROXIES</h2>
            <h3>Stop questioning your success</h3>
            <Link to="/pricing"><button>View Plans</button></Link>
          </div>
          <img src={join} alt=" " />
       </div>
      </div>
    </div>
  )
}
